import html from '../html.js';
import {useLocalize} from '../locale.js';

import AffinityGraph from './affinity-graph.js';
import AffinityTable from './affinity-table.js';
import ExternalLink from './external-link.js';
import {SCORE_ANSWER} from '../dummy.js';

/** @typedef { import("../player").default } Player */

/**
 * @param {object} props
 * @param {Player[]} props.players
 */
export default ({players}) => {
	const localize = useLocalize();
	return html`
		<p>
			${localize('AFFINITY_CHART_DESC')}
			<${ExternalLink} href="${SCORE_ANSWER}">
				${localize('AFFINITY_CHART_SOURCE_LINK_TEXT')}
			</${ExternalLink}>
		</p>
		<${AffinityGraph} players=${players} />

		<p>${localize('AFFINITY_TABLE_DESC')}</p>
		<${AffinityTable} players=${players} />
	`;
};
