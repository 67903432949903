import {useEffect, useState} from '../_snowpack/pkg/preact/hooks.js';

/**
 * Calculate the number of milliseconds between the current time and some input
 * time (represented as milliseconds since the Unix epoch) with 1-second
 * precision.
 *
 * @param {number} [endTime]
 */
export default (endTime) => {
	const [nowSecond, setNowSecond] = useState(Math.round(Date.now() / 1000));

	useEffect(() => {
		const timerId = setTimeout(() => {
			setNowSecond(Math.round(Date.now() / 1000));
		}, 1000);

		return () => clearTimeout(timerId);
	}, [endTime, nowSecond]);

	return endTime ? Math.max(0, endTime - Date.now()) : 0;
};
