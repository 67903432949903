import {useCallback, useMemo, useState} from '../_snowpack/pkg/preact/hooks.js';

import {useLocale, useLocalize} from '../locale.js';
import html from '../html.js';
import {PlayerUnknown} from '../player.js';
import Modal from './modal.js';
import {MAX_TEXT_LENGTH} from '../validation.js';

import './speech.css';

/** @typedef { import("preact").ComponentChildren } ComponentChildren */
/** @typedef {import("../player").default} Player */
/** @typedef {import("../../types").AllAnswers} AllAnswers */
/** @typedef {import("../../types").AllGuesses} AllGuesses */
/** @typedef {import("../../types").Answer} Answer */
/** @typedef {import("../../types").Guess} Guess */

const nbsp = String.fromCharCode(160);

/** @typedef {() => any} Intl.ListFormat */

/**
 * @param {object} props
 * @param {string[]} [props.alternates]
 */
const Alternates = ({alternates}) => {
	const localize = useLocalize();
	const listAnd = new Intl.ListFormat(
		useLocale(),
		{style: 'long', type: 'conjunction'}
	);

	if (!alternates || !alternates.length) {
		return null;
	}

	return html`
		<span class="alternates">
			${localize(
				'ALTERNATE_ANSWERS',
				listAnd.format(alternates.map((alternate) => `"${alternate}"`))
			)}
		</span>
	`;
};

/**
 * @param {object} props
 * @param {Player} props.player
 */
const Avatar = ({player}) => {
	return html`
		<div
			class="avatar"
			style=${{backgroundColor: player.color}}
			>
			${player.initial || nbsp}
		</div>
	`;
};

/**
 * @param {AllAnswers} allAnswers
 * @param {string} actualAuthor
 * @param {string | null} suspectedAuthor
 */
const isCorrect = (allAnswers, actualAuthor, suspectedAuthor) => {
	if (suspectedAuthor === actualAuthor) {
		return true;
	}

	const equivalence = allAnswers[actualAuthor] &&
		allAnswers[actualAuthor]
			.equivalences.find(({author}) => author === suspectedAuthor);

	return equivalence && equivalence.verified;
};

/**
 * @param {object} props
 * @param {string} props.className
 * @param {number} props.value
 * @param {number} props.max
 * @param {ComponentChildren} props.children
 */
const OverallPart = ({className, value, max, children}) => {
	if (value === 0) {
		return null;
	}

	return html`<span class=${className} style="width: ${100 * value / max}%">
		${children}
	</span>`;
};

/**
 * @param {object} props
 * @param {Player} props.player
 * @param {object} props.results
 * @param {AllGuesses} props.results.allGuesses
 * @param {Player} props.results.me
 * @param {Player[]} props.results.players
 * @param {AllAnswers} props.results.allAnswers
 */
const Results = ({player, results: {allGuesses, me, players, allAnswers}}) => {
	const localize = useLocalize();
	const myGuesses = allGuesses[me.name];
	const myGuess = myGuesses && myGuesses[player.name] ?
		players.find(({name}) => name === myGuesses[player.name].suspectedAuthor) :
		null;
	const myGuessAvatar = myGuess ?
		html`<${Avatar} player=${myGuess} />${myGuess.name}` :
		html`<${Avatar} player=${new PlayerUnknown()} />`;
	let className = 'my-guess';
	let pointText = '';

	if (player === me) {
		className += ' myself';
	} if (!myGuess) {
		className += ' none';
	} else if (isCorrect(allAnswers, player.name, myGuess.name)) {
		className += ' correct';
		pointText = localize('POINT_DELTA', '+1');
	} else {
		className += ' incorrect';
		pointText = localize('POINT_DELTA', '-1');
	}

	/** @type {Guess[]} */
	const guesses = Object.values(allGuesses)
		.map((guesses) => guesses[player.name])
		.filter((guess) => !!guess);
	const correct = guesses
		.filter(({suspectedAuthor, actualAuthor}) => {
			return isCorrect(allAnswers, actualAuthor, suspectedAuthor);
		})
		.length;
	const incorrect = guesses.length - correct;
	// Subectract at least `1` to account for the answer's author because
	// players are not able to guess the author of their own answers.
	const maxPossible = players.length - 1;
	const skipped = maxPossible - correct - incorrect;

	return html`
		<div class="results">
			<div class=${className}>
				<div class="label">${localize('YOUR_GUESS')}</div>
				${myGuessAvatar}
				<span class="points">${pointText}</span>
			</div>

			<div class="label">${localize('OVERALL')}</div>
			<div class="all-guesses">
				<${OverallPart} className="part correct" value=${correct} max=${maxPossible}>
					${localize('COUNT_CORRECT', correct)}
				</${OverallPart}>
				<${OverallPart} className="part skipped" value=${skipped} max=${maxPossible}>
					${localize('COUNT_SKIPPED', skipped)}
				</${OverallPart}>
				<${OverallPart} className="part incorrect" value=${incorrect} max=${maxPossible}>
					${localize('COUNT_INCORRECT', incorrect)}
				</${OverallPart}>
			</div>
		</ul>
	`;
};

/**
 * @param {object} props
 * @param {Player} props.player
 * @param {string} props.message
 * @param {string[]} props.alternates
 * @param {object} [props.results]
 * @param {AllGuesses} props.results.allGuesses
 * @param {Player} props.results.me
 * @param {AllAnswers} props.results.allAnswers
 */
export const Speech = ({player, message, alternates, results}) => {
	const className = `speech ${player.name ? '' : 'anonymous'}`;

	const summary = results ?
		html`<${Results} player=${player} results=${results} />` :
		null;

	return html`
		<div class=${className}>
			<div class="author">
				<${Avatar} player=${player} />
				<div class="name">${player.name || nbsp}</div>
			</div>

			<div class="body">
				<p class="message">
					${message}
					<${Alternates} alternates=${alternates} />
				</p>
				${summary}
			</div>
		</div>
	`;
};

/**
 * @param {object} props
 * @param {Player} props.player
 * @param {string} props.message
 * @param {(value: string) => void} props.onInput
 */
export const SpeechInput = ({player, message, onInput}) => {
	const localize = useLocalize();
	const className = `speech ${player.name ? '' : 'anonymous'}`;

	return html`
		<div class=${className}>
			<div class="author">
				<${Avatar} player=${player} />
				<div class="name">${player.name || nbsp}</div>
			</div>

			<div class="body">
				<textarea
					maxLength=${MAX_TEXT_LENGTH}
					onInput=${onInput}
					placeholder=${localize('ENTER_ANSWER')}>
					${message}
				</textarea>
			</p>
		</div>
	`;
};

/**
 * @param {object} props
 * @param {Answer} props.answer
 * @param {null | Answer} props.myAnswer
 * @param {(suspectedAuthor: string | null) => void} props.onChange
 * @param {(name: string) => void} props.onMatch
 * @param {Answer[]} props.answers
 * @param {Player[]} props.players
 * @param {string[]} props.alternates
 */
export const SpeechGuess = ({answer, myAnswer, onChange, onMatch, answers, players, alternates}) => {
	const localize = useLocalize();
	const unknown = useMemo(() => new PlayerUnknown(), []);
	const [guess, setGuess] = useState(unknown);
	const className = `speech speech-guess ${guess.name ? '' : 'anonymous'}`;
	const [isSelecting, setIsSelecting] = useState(false);
	const onClick = useCallback(
		/**
		 * @param {InputEvent} event
		 */
		(event) => {
			const button =/**@type {HTMLButtonElement}*/(event.target)
				.closest('button');
			if (!button) {
				return;
			}

			const player = players.find(({name}) => {
				return name === button.value;
			});

			setGuess(player || unknown);
			onChange(player ? player.name : null);
			setIsSelecting(false);
		},
		[players, onChange]
	);
	const options = [unknown, ...players].map((player) => {
		return html`
			<button
				onClick=${onClick}
				value=${player.name}>
				<${Avatar} player=${player} />
				<div class="name">${player.name}</div>
			</button>
		`;
	});
	const needsButton = myAnswer &&
		!myAnswer.equivalences.find(({author}) => author === answer.author);

	return html`
		<div class=${className}>
			<div class="author">
				<button
					aria-label="${localize('GUESS_THE_AUTHOR')}"
					onClick=${() => setIsSelecting(true)}>
						<${Avatar} player=${guess} />
						<div class="name">${guess.name}</div>
				</button>
			</div>

			<div class="body">
				<p class="message">
					${answer.text}
					<${Alternates} alternates=${alternates} />
				</p>
				<button
					disabled=${!needsButton}
					class="equivalence"
					onClick=${() => onMatch(answer.author)}>
						${localize('ANSWER_MATCHES_MINE')}
				</button>
			</div>

			<${Modal}
				title=${localize('GUESS_THE_AUTHOR')}
				isActive=${isSelecting}
				onClose=${() => setIsSelecting(false)}
				>
				${options}
			</${Modal}>
		</div>
	`;
};
