import { useCallback, useEffect, useState } from '../_snowpack/pkg/preact/hooks.js';
import html from '../html.js';
import {useLocalize} from '../locale.js';

import Player from '../player.js';
import {Speech} from './speech.js';
import Demo from './demo.js';
import {MAX_PLAYER_NAME_LENGTH} from '../validation.js';

import './intro.css';

/**
 * @typedef { import("../../types").Player } PlayerData
 */

/**
 * @param {object} props
 * @param {string} props.name
 * @param {PlayerData[]} props.players
 * @param {Function} props.onJoin
 */
export default ({name: _name, players, onJoin}) => {
	const localize = useLocalize();
	const [name, setName] = useState('');
	const player = new Player({
		name, cxnId: null, avatar: null, question: null, standings: {}
	});
	const [isViewingDemo, setIsViewingDemo] = useState(false);

	useEffect(() => {
		setName(_name);
	}, [_name]);

	const onSubmit = useCallback((/**@type {InputEvent}*/event) => {
		event.preventDefault();
		onJoin(name);
	}, [onJoin, name]);

	/**
	 * @param {InputEvent} event
	 */
	const onInput = (event) => {
		setName(/**@type {HTMLInputElement}/*/(event.target).value.trim());
	};

	/**
	 * @param {InputEvent} event
	 */
	const onToggle = (event) => {
		setIsViewingDemo(/**@type {HTMLDetailsElement}/*/(event.target).open);
	};

	return html`
		<form class="intro" onSubmit=${onSubmit}>
			<p>${localize('WHAT_IS_FRIENDOFF_ANSWER_P1')}</p>

			<details onToggle=${onToggle}>
				<summary>${localize('WATCH_A_DEMO')}</summary>

				${isViewingDemo ? html`<${Demo} autoplay=true />` : null}
			</details>

			<p>${localize('WHAT_IS_FRIENDOFF_ANSWER_P2')}</p>

			<p>${localize('INTRODUCE_YOURSELF')}</p>

			<input
				value=${name}
				maxLength=${MAX_PLAYER_NAME_LENGTH}
				onInput=${onInput}
				/>

			<p>${localize('NAME_EXPLAINED')}</p>

			<${Speech}
				player=${player}
				message="${localize('SAMPLE_GREETING', player.name)}"
				/>

			<div class="controls">
				<input
					type="submit"
					disabled=${!name}
					value="${localize('JOIN_GAME')}" />
			</div>
		</form>
	`;
};
