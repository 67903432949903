export var ADVANCE_AFTER = "Vote received!";
export var ADVANCE_BEFORE = "Vote to advance";
export var ADVANCE_FROM_GUESSING = "Vote to review results";
export var ADVANCE_FROM_JOINING = "Vote to begin";
export var ADVANCE_FROM_REVIEWING = "Vote for new round";
export var AFFINITIES = "Affinities";
export var AFFINITY_CHART_DESC = "In this chart, players who correctly guess each others' answers are displayed closer together, while players who incorrectly guess each others' answers are displayed farther apart. When you make an incorrect guess, you'll be distanced from both the actual author and the player you misattributed.";
export var AFFINITY_CHART_SOURCE_LINK_TEXT = "You can review the source code which manages affinities here.";
export var AFFINITY_TABLE_DESC = "This table displays a score for every pair of players: the number of answers they guessed correctly less the number of answers they guessed incorrectly.\n";
export var ALTERNATE_ANSWERS = "Alternates: {1}";
export var ANSWER_MATCHES_MINE = "Match with yours";
export var ANSWER_RECEIVED = "Answer received!";
export var CLOSE = "Close";
export var CONNECTION_ERROR = "Connection error";
export var CONNECTION_ERROR_DESC = "You've lost your connection to the server. We'll keep trying to reconnect, and you can try refreshing the page.";
export var COUNT_CORRECT = "{1} correct";
export var COUNT_INCORRECT = "{1} incorrect";
export var COUNT_SKIPPED = "{1} skipped";
export var COUNTDOWN_AUDIO_OFF = "Countdown audio is off";
export var COUNTDOWN_AUDIO_ON = "Countdown audio is on";
export var CREDITS = "Credits";
export var CREDITS_FONT_AWESOME = "This application's menu icons are provided by <a href=\"https://github.com/FortAwesome/Font-Awesome/blob/master/LICENSE.txt\">Font Awesome</a> under <a href=\"https://creativecommons.org/licenses/by/4.0/\">the Creative Commons Attribution 4.0 International license</a>.";
export var CREDITS_I18N_LOGO = "This <a href=\"https://commons.wikimedia.org/wiki/File:%C3%86toms_-_Translation.svg\"> Internationalization (\"i18n\")icon</a> provided by <a href=\"https://commons.wikimedia.org/wiki/User:%C3%86toms\">Ætoms</a> under the <a href=\"https://creativecommons.org/licenses/by-sa/4.0\">CC BY-SA 4.0 license</a>, via <a href=\"\">Wikimedia Commons</a>";
export var DEMO_ANSWER_1 = "The Beatles";
export var DEMO_ANSWER_2 = "Justin Timberlake";
export var DEMO_ANSWER_3 = "Jaya the Cat";
export var DEMO_ANSWER_4 = "Gavin Degraw";
export var DEMO_ANSWER_5 = "The Kinks";
export var DEMO_ANSWER_6 = "Van Morrison";
export var DEMO_QUESTION = "What's your favorite music group?";
export var DEMO_YOU_ASKED = "You asked, \"{1}\"";
export var ENTER_ANSWER = "Enter your answer here";
export var ENTER_ROOM = "Enter room";
export var ERR_ADVANCE_OUT_OF_PHASE = "Request to advance received during inappropriate phase ({1})";
export var ERR_ANSWER_NOT_FOUND = "No answer from player {1}.";
export var ERR_ANSWER_OUT_OF_PHASE = "Answer received during inappropriate phase ({1})";
export var ERR_GUESS_OUT_OF_PHASE = "Guess received during inappropriate phase ({1})";
export var ERR_LOST_CXN = "Connection lost";
export var ERR_MATCH_OUT_OF_PHASE = "Match received during inappropriate phase ({1})";
export var ERR_PLAYER_ALREADY_ADVANCED = "Request to advance already received";
export var ERR_UNKNOWN = "Unknown error: {1}";
export var ERR_UNRECOGNIZED_CXN = "Unrecognized connection";
export var ERROR_LABEL = "Error";
export var ERROR_LOG = "Error Log";
export var ERROR_MESSAGE = "Message";
export var ERROR_TIME = "Time";
export var ERRORS = "Errors";
export var FREE_SOFTWARE_DEPENDENCIES = "Friend Off! includes code from the following {1} free software projects:";
export var FRIEND_OFF_IS_FREE_SOFTWARE_LONG = "Friend Off! is free software and distributed under the terms of the GPLv3 license.";
export var FRIEND_OFF_IS_FREE_SOFTWARE_SHORT = "Friend Off! is free software.";
export var GUESS_THE_AUTHOR = "Guess the author";
export var HELP = "Help";
export var INTRODUCE_YOURSELF = "Ready to play? Then introduce yourself!";
export var JOIN_GAME = "Join the game!";
export var LICENSES = "Licenses";
export var LINK_OPENS_IN_NEW_WINDOW = "This link opens in a new window.";
export var NAME_EXPLAINED = "Your name is how the other players will recognize you when you share questions and answers. Here's how your messages will appear to others:";
export var OVERALL = "Overall";
export var PHASE = "Phase";
export var PHASE_ANSWERING_DESC = "Your response to this question will be shared anonymously in the next phase. This phase ends when time expires.";
export var PHASE_ANSWERING_NAME = "Answering";
export var PHASE_ASKING_DESC = "Enter a question to pose to the group. Questions will be selected in the order they're received. This phase ends when someone asks a question.";
export var PHASE_ASKING_SOURCE_LINK_TEXT = "You can review the source code which selects questions here.";
export var PHASE_ASKING_NAME = "Asking";
export var PHASE_ASKING_PROMPT = "No one has a question queued up. There's a link in the menu to enter a question--use it now to start another round.";
export var PHASE_GUESSING_DESC = "Try to determine the player that wrote each response. Any two players can anonymously decide that their answers match so that everyone can attribute them interchangeably. This phase ends when half the players vote to advance.";
export var PHASE_GUESSING_NAME = "Guessing";
export var PHASE_JOINING_DESC = "Wait for your friends to join. This phase ends when at least three players join and everyone votes to advance.";
export var PHASE_JOINING_NAME = "Joining";
export var PHASE_JOINING_SUGGESTION = "Why not write a question for the group while you wait?";
export var PHASE_REVIEWING_DESC = "Check out how everyone did in this round. This phase ends when half the players vote to advance.";
export var PHASE_REVIEWING_NAME = "Reviewing";
export var PHASE_WITH_ROUND = "Phase (round {1})";
export var PICK_A_ROOM_NAME = "Pick a room name to get started!";
export var PLAY_DEMO = "Play demo";
export var PLAYER_ASKS = "{1} asks, \"{2}\"";
export var POINT_DELTA = "{1} point";
export var QUESTION_ENTRY_EXPLANATION = "Each round, a new question is randomly selected from all the players. Enter a question here to be considered each time a round begins. (You can change it whenever you like.)";
export var SAMPLE_GREETING = "Hi, my name is {1}!";
export var SAVE = "Save";
export var STANDINGS = "Standings";
export var TIME_REMAINING = "Time remaining";
export var TRANSLATING_FRIEND_OFF = "Translating Friend Off";
export var TRANSLATING_FRIEND_OFF_EXPLAINED = "Would you like to play Friend Off in your native language? Good news: the game was designed to be translated into other languages. The maintainers are excited to help you translate--no programming required!";
export var TRANSLATING_FRIEND_OFF_SOURCE_LINK_TEXT = "You can find a guide to translating the game here.";
export var VERSION = "version";
export var VIEW_LICENSES = "View licenses";
export var WATCH_A_DEMO = "Watch a demo";
export var WHAT_IS_FRIENDOFF = "What is Friend Off?";
export var WHAT_IS_FRIENDOFF_ANSWER_P1 = "This is a game for people to see how well they know each other and maybe learn a thing or two. One player asks a question, everyone submits their answer in secret, and then each player tries to guess who said what. We'll keep a leader board, and we'll also see if any players know each other particularly well.";
export var WHAT_IS_FRIENDOFF_ANSWER_P2 = "Your answers will be shared with all the other players, but they won't be stored or shared outside of this session.";
export var WHAT_IS_FRIENDOFF_ANSWER_SOURCE_LINK_TEXT = "You can review the source code which destroys empty rooms here.";
export var WHAT_HAPPENS_IN_EACH_PHASE = "What happens in each phase?";
export var WHAT_MAKES_IT_FOSS = "What make this free and open source software?";
export var WHAT_MAKES_IT_FOSS_ANSWER_1 = "\"Free software\" means software that respects users' freedom and community. Roughly, it means that the users have the freedom to run, copy, distribute, study, change and improve the software. Thus, \"free software\" is a matter of liberty, not price. To understand the concept, you should think of \"free\" as in \"free speech,\" not as in \"free beer\". We sometimes call it \"libre software,\" borrowing the French or Spanish word for \"free\" as in freedom, to show we do not mean the software is gratis.";
export var WHAT_MAKES_IT_FOSS_ANSWER_2 = "You can review the source code of the entire project, and the game itself includes links to particularly important regions of code.";
export var WHAT_MAKES_IT_FOSS_ANSWER_SOURCE_LINK_TEXT = "For example, here's the source code which causes this text to appear on your screen.";
export var YOUR_GUESS = "Your guess";
export var YOUR_QUESTION = "Your Question";
export default {
	ADVANCE_AFTER: ADVANCE_AFTER,
	ADVANCE_BEFORE: ADVANCE_BEFORE,
	ADVANCE_FROM_GUESSING: ADVANCE_FROM_GUESSING,
	ADVANCE_FROM_JOINING: ADVANCE_FROM_JOINING,
	ADVANCE_FROM_REVIEWING: ADVANCE_FROM_REVIEWING,
	AFFINITIES: AFFINITIES,
	AFFINITY_CHART_DESC: AFFINITY_CHART_DESC,
	AFFINITY_CHART_SOURCE_LINK_TEXT: AFFINITY_CHART_SOURCE_LINK_TEXT,
	AFFINITY_TABLE_DESC: AFFINITY_TABLE_DESC,
	ALTERNATE_ANSWERS: ALTERNATE_ANSWERS,
	ANSWER_MATCHES_MINE: ANSWER_MATCHES_MINE,
	ANSWER_RECEIVED: ANSWER_RECEIVED,
	CLOSE: CLOSE,
	CONNECTION_ERROR: CONNECTION_ERROR,
	CONNECTION_ERROR_DESC: CONNECTION_ERROR_DESC,
	COUNT_CORRECT: COUNT_CORRECT,
	COUNT_INCORRECT: COUNT_INCORRECT,
	COUNT_SKIPPED: COUNT_SKIPPED,
	COUNTDOWN_AUDIO_OFF: COUNTDOWN_AUDIO_OFF,
	COUNTDOWN_AUDIO_ON: COUNTDOWN_AUDIO_ON,
	CREDITS: CREDITS,
	CREDITS_FONT_AWESOME: CREDITS_FONT_AWESOME,
	CREDITS_I18N_LOGO: CREDITS_I18N_LOGO,
	DEMO_ANSWER_1: DEMO_ANSWER_1,
	DEMO_ANSWER_2: DEMO_ANSWER_2,
	DEMO_ANSWER_3: DEMO_ANSWER_3,
	DEMO_ANSWER_4: DEMO_ANSWER_4,
	DEMO_ANSWER_5: DEMO_ANSWER_5,
	DEMO_ANSWER_6: DEMO_ANSWER_6,
	DEMO_QUESTION: DEMO_QUESTION,
	DEMO_YOU_ASKED: DEMO_YOU_ASKED,
	ENTER_ANSWER: ENTER_ANSWER,
	ENTER_ROOM: ENTER_ROOM,
	ERR_ADVANCE_OUT_OF_PHASE: ERR_ADVANCE_OUT_OF_PHASE,
	ERR_ANSWER_NOT_FOUND: ERR_ANSWER_NOT_FOUND,
	ERR_ANSWER_OUT_OF_PHASE: ERR_ANSWER_OUT_OF_PHASE,
	ERR_GUESS_OUT_OF_PHASE: ERR_GUESS_OUT_OF_PHASE,
	ERR_LOST_CXN: ERR_LOST_CXN,
	ERR_MATCH_OUT_OF_PHASE: ERR_MATCH_OUT_OF_PHASE,
	ERR_PLAYER_ALREADY_ADVANCED: ERR_PLAYER_ALREADY_ADVANCED,
	ERR_UNKNOWN: ERR_UNKNOWN,
	ERR_UNRECOGNIZED_CXN: ERR_UNRECOGNIZED_CXN,
	ERROR_LABEL: ERROR_LABEL,
	ERROR_LOG: ERROR_LOG,
	ERROR_MESSAGE: ERROR_MESSAGE,
	ERROR_TIME: ERROR_TIME,
	ERRORS: ERRORS,
	FREE_SOFTWARE_DEPENDENCIES: FREE_SOFTWARE_DEPENDENCIES,
	FRIEND_OFF_IS_FREE_SOFTWARE_LONG: FRIEND_OFF_IS_FREE_SOFTWARE_LONG,
	FRIEND_OFF_IS_FREE_SOFTWARE_SHORT: FRIEND_OFF_IS_FREE_SOFTWARE_SHORT,
	GUESS_THE_AUTHOR: GUESS_THE_AUTHOR,
	HELP: HELP,
	INTRODUCE_YOURSELF: INTRODUCE_YOURSELF,
	JOIN_GAME: JOIN_GAME,
	LICENSES: LICENSES,
	LINK_OPENS_IN_NEW_WINDOW: LINK_OPENS_IN_NEW_WINDOW,
	NAME_EXPLAINED: NAME_EXPLAINED,
	OVERALL: OVERALL,
	PHASE: PHASE,
	PHASE_ANSWERING_DESC: PHASE_ANSWERING_DESC,
	PHASE_ANSWERING_NAME: PHASE_ANSWERING_NAME,
	PHASE_ASKING_DESC: PHASE_ASKING_DESC,
	PHASE_ASKING_SOURCE_LINK_TEXT: PHASE_ASKING_SOURCE_LINK_TEXT,
	PHASE_ASKING_NAME: PHASE_ASKING_NAME,
	PHASE_ASKING_PROMPT: PHASE_ASKING_PROMPT,
	PHASE_GUESSING_DESC: PHASE_GUESSING_DESC,
	PHASE_GUESSING_NAME: PHASE_GUESSING_NAME,
	PHASE_JOINING_DESC: PHASE_JOINING_DESC,
	PHASE_JOINING_NAME: PHASE_JOINING_NAME,
	PHASE_JOINING_SUGGESTION: PHASE_JOINING_SUGGESTION,
	PHASE_REVIEWING_DESC: PHASE_REVIEWING_DESC,
	PHASE_REVIEWING_NAME: PHASE_REVIEWING_NAME,
	PHASE_WITH_ROUND: PHASE_WITH_ROUND,
	PICK_A_ROOM_NAME: PICK_A_ROOM_NAME,
	PLAY_DEMO: PLAY_DEMO,
	PLAYER_ASKS: PLAYER_ASKS,
	POINT_DELTA: POINT_DELTA,
	QUESTION_ENTRY_EXPLANATION: QUESTION_ENTRY_EXPLANATION,
	SAMPLE_GREETING: SAMPLE_GREETING,
	SAVE: SAVE,
	STANDINGS: STANDINGS,
	TIME_REMAINING: TIME_REMAINING,
	TRANSLATING_FRIEND_OFF: TRANSLATING_FRIEND_OFF,
	TRANSLATING_FRIEND_OFF_EXPLAINED: TRANSLATING_FRIEND_OFF_EXPLAINED,
	TRANSLATING_FRIEND_OFF_SOURCE_LINK_TEXT: TRANSLATING_FRIEND_OFF_SOURCE_LINK_TEXT,
	VERSION: VERSION,
	VIEW_LICENSES: VIEW_LICENSES,
	WATCH_A_DEMO: WATCH_A_DEMO,
	WHAT_IS_FRIENDOFF: WHAT_IS_FRIENDOFF,
	WHAT_IS_FRIENDOFF_ANSWER_P1: WHAT_IS_FRIENDOFF_ANSWER_P1,
	WHAT_IS_FRIENDOFF_ANSWER_P2: WHAT_IS_FRIENDOFF_ANSWER_P2,
	WHAT_IS_FRIENDOFF_ANSWER_SOURCE_LINK_TEXT: WHAT_IS_FRIENDOFF_ANSWER_SOURCE_LINK_TEXT,
	WHAT_HAPPENS_IN_EACH_PHASE: WHAT_HAPPENS_IN_EACH_PHASE,
	WHAT_MAKES_IT_FOSS: WHAT_MAKES_IT_FOSS,
	WHAT_MAKES_IT_FOSS_ANSWER_1: WHAT_MAKES_IT_FOSS_ANSWER_1,
	WHAT_MAKES_IT_FOSS_ANSWER_2: WHAT_MAKES_IT_FOSS_ANSWER_2,
	WHAT_MAKES_IT_FOSS_ANSWER_SOURCE_LINK_TEXT: WHAT_MAKES_IT_FOSS_ANSWER_SOURCE_LINK_TEXT,
	YOUR_GUESS: YOUR_GUESS,
	YOUR_QUESTION: YOUR_QUESTION
};
