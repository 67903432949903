import {useEffect, useRef, useState} from '../_snowpack/pkg/preact/hooks.js';

import html from '../html.js';
import Player from '../player.js';
import {useLocalize} from '../locale.js';
import {Speech, SpeechInput, SpeechGuess} from './speech.js';
import QuestionEntry from './question-entry.js';
import AffinityGraph from './affinity-graph.js';
import ExternalLink from './external-link.js';
import {CHOOSE_QUESTION} from '../dummy.js';

import './demo.css';

const playerNames = ['You', 'Yve', 'Matt', 'Caroline', 'Mark', 'Karen'];
const makeStandings = () => playerNames.reduce((standings, name) => {
	standings[name] = [0, 0, 0];
	return standings;
}, /**@type Player['standings']*/({}));

const players = playerNames.map((name) => {
	const standings = makeStandings();

	if (name === 'You') {
		standings.Matt[0] += 1;
		standings.Karen[0] += 1;
		standings.Mark[1] += 1;
		standings.Yve[2] += 1;
	}
	return new Player({
		name, cxnId: null, avatar: null, question: null, standings
	});
});
const results = {
	players,
	me: players[0],
	allAnswers: {}, // This value is inaccurate, but it doesn't affect the demo.
	allGuesses: {
		You: {
			Karen: {actualAuthor: 'Karen', suspectedAuthor: 'Karen'},
			Matt: {actualAuthor: 'Matt', suspectedAuthor: 'Matt'},
			Yve: {actualAuthor: 'Yve', suspectedAuthor: 'Mark'},
		},
	},
};

const noop = () => {};

const phaseDurations = [9000, 7000, 9000, 12000];
const totalDuration = phaseDurations.reduce((a, b) => a + b);

/**
 * @param {string} text
 * @param {HTMLTextAreaElement} element
 * @param {number} delay
 */
const type = (text, element, delay) => {
	const letters = text.split('');
	let timerId = setTimeout(() => {
		(function one() {
			element.value += letters.shift();

			if (letters.length) {
				timerId = setTimeout(one, 100 + Math.random() * 80);
			}
		}());
	}, delay);

	return () => clearTimeout(timerId);
};

/**
 * @param {HTMLElement} element
 */
const makeInputsUnreachable = (element) => {
	Array.from(element.querySelectorAll(
		'.browser input, .browser button, .browser textarea'
	)).forEach((input) => {
		input.setAttribute('tabindex', '-1');
	});
};

/**
 * @param {object} props
 * @param {boolean} [props.autoplay]
 */
export default ({autoplay}) => {
	const localize = useLocalize();
	const [activePhase, setActivePhase] = useState(autoplay ? 0 : -1);
	const [firstGuess, setFirstGuess] = useState(0);
	const [secondGuess, setSecondGuess] = useState(0);
	const [thirdGuess, setThirdGuess] = useState(0);
	const ref = useRef(/**@type {null|HTMLElement}*/(null));

	const question = localize('DEMO_QUESTION');
	const answers = [
		{author: playerNames[0], equivalences: [], text: localize('DEMO_ANSWER_1')},
		{author: playerNames[1], equivalences: [], text: localize('DEMO_ANSWER_2')},
		{author: playerNames[2], equivalences: [], text: localize('DEMO_ANSWER_3')},
		{author: playerNames[3], equivalences: [], text: localize('DEMO_ANSWER_4')},
		{author: playerNames[4], equivalences: [], text: localize('DEMO_ANSWER_5')},
		{author: playerNames[5], equivalences: [], text: localize('DEMO_ANSWER_6')},
	];

	useEffect(() => {
		if (ref.current) {
			makeInputsUnreachable(ref.current);
		}
	}, [ref]);

	useEffect(() => {
		if (activePhase == -1) {
			return;
		}

		const nextPhase = (activePhase + 1) % phaseDurations.length;
		const delay = phaseDurations[activePhase];
		/** @type {HTMLTextAreaElement | null} */
		const questionEntry = ref.current &&
			ref.current.querySelector('[data-phase=\'0\'] textarea');
		/** @type {HTMLTextAreaElement | null} */
		const answerEntry = ref.current &&
			ref.current.querySelector('[data-phase=\'1\'] textarea');
		/** @type {ReturnType<setTimeout>[]} */
		const timerIds = [];
		let cancelTyping = noop;

		timerIds.push(setTimeout(() => {
			setActivePhase(nextPhase);
		}, delay));

		if (activePhase === 0 && questionEntry) {
			questionEntry.value = '';
			cancelTyping = type(question, questionEntry, 2500);
		}

		if (activePhase === 1 && answerEntry) {
			answerEntry.value = '';
			cancelTyping = type(answers[0].text, answerEntry, 2500);
		}

		if (activePhase === 2) {
			timerIds.push(setTimeout(() => setFirstGuess(1), 4000));
			timerIds.push(setTimeout(() => setSecondGuess(1), 6000));
			timerIds.push(setTimeout(() => setThirdGuess(1), 7000));
		} else {
			setFirstGuess(0);
			setSecondGuess(0);
			setThirdGuess(0);
		}

		return () => {
			timerIds.forEach((timerId) => clearTimeout(timerId));
			cancelTyping();
		};
	}, [ref, activePhase]);

	return html`
		<div
			ref=${ref}
			data-active-phase=${activePhase}
			role="presentation"
			class="demo">

		<button
			aria-label=${localize('PLAY_DEMO')}
			onClick=${() => setActivePhase(0)}
			class="faux-video-surface">
		</button>

		<header>
			<div class="phase-items">
				<div data-phase="0">
					<h1>${localize('PHASE')} 1 ("${localize('PHASE_ASKING_NAME')}")</h1>
					<h2>
					${localize('PHASE_ASKING_DESC')}
					<${ExternalLink} href="${CHOOSE_QUESTION}">
						${localize('PHASE_ASKING_SOURCE_LINK_TEXT')}
					</${ExternalLink}>
					</h2>
				</div>

				<div data-phase="1">
					<h1>${localize('PHASE')} 2 ("${localize('PHASE_ANSWERING_NAME')}")</h1>
					<h2>${localize('PHASE_ANSWERING_DESC')}</h2>
				</div>

				<div data-phase="2">
					<h1>${localize('PHASE')} 3 ("${localize('PHASE_GUESSING_NAME')}")</h1>

					<h2>${localize('PHASE_GUESSING_DESC')}</h2>
				</div>

				<div data-phase="3">
					<h1>${localize('PHASE')} 4 ("${localize('PHASE_REVIEWING_NAME')}")</h1>
					<h2>${localize('PHASE_REVIEWING_DESC')}</h2>
				</div>
			</div>
		</header>

		<div class="browser">
			<div class="toolbar">
				<div class="controls">X</div>
				<div class="location">${location.href}</div>
			</div>

			<div class="viewport">
				<div class="phase-items">
					<div data-phase="0">
						<${QuestionEntry}
							player=${players[0]}
							onChange=${noop} />
					</div>

					<div data-phase="1">
						<h1>${localize('DEMO_YOU_ASKED', question)}</h1>

						<${SpeechInput}
							player=${players[0]}
							message=""
							onInput=${noop}
							/>
					</div>

					<div data-phase="2">
						<h1>${localize('DEMO_YOU_ASKED', question)}</h1>

						<div
							data-first-guess=${firstGuess}
							data-second-guess=${secondGuess}
							data-third-guess=${thirdGuess}>

							<${Speech} player=${players[0]} message=${answers[0].text} />
							<${Speech} player=${players[4]} message=${answers[1].text} />
							<${SpeechGuess} players=${players} answer=${answers[1]} myAnswer=${null} />
							<${Speech} player=${players[2]} message=${answers[2].text} />
							<${SpeechGuess} players=${players} answer=${answers[2]} myAnswer=${null} />
							<${SpeechGuess} players=${players} answer=${answers[3]} myAnswer=${null} />
							<${SpeechGuess} players=${players} answer=${answers[4]} myAnswer=${null} />
							<${Speech} player=${players[5]} message=${answers[5].text} />
							<${SpeechGuess} players=${players} answer=${answers[5]} myAnswer=${null} />
						</div>
					</div>

					<div data-phase="3">
						<h1>${localize('DEMO_YOU_ASKED', question)}</h1>

						<div class="graph-screen">

							<h1>${localize('AFFINITIES')}</h1>

							<${AffinityGraph} players=${players} />
						</div>

						<div>
							<${Speech} player=${players[0]} message=${answers[0].text} results=${results} />
							<${Speech} player=${players[1]} message=${answers[1].text} results=${results} />
							<${Speech} player=${players[2]} message=${answers[2].text} results=${results} />
							<${Speech} player=${players[3]} message=${answers[3].text} results=${results} />
							<${Speech} player=${players[4]} message=${answers[4].text} results=${results} />
							<${Speech} player=${players[5]} message=${answers[5].text} results=${results} />
						</div>
					</div>
				</div>
			</div>

			<div class="progress">
				<div class="fill" style="animation-duration: ${totalDuration}ms"></div>
			</div>
		</div>
	</div>`;
};
