/**
 * An implementation of the "modal" design pattern from the WAI-ARIA Authoring
 * Practices guideline.
 *
 * https://www.w3.org/TR/wai-aria-practices-1.1
 */
import { useCallback, useEffect, useRef, useState } from '../_snowpack/pkg/preact/hooks.js';

import html from '../html.js';
import keyCodes from '../key-codes.js';
import { useLocalize } from '../locale.js';
import LocaleMenu from './locale-menu.js';

import './modal.css';

/** @typedef { import("preact").ComponentChildren } ComponentChildren */

let count = 0;

/**
 * @param {HTMLElement} element
 */
const attemptFocus = (element) => {
	try {
		element.focus();
	// Work around bug in JSHint
	// https://github.com/jshint/jshint/issues/3480
	// jshint -W137
	} catch ({}) {}
	// jshint +W137

	return element.matches(':focus');
};

/**
 * @param {'first' | 'last'} which
 * @param {HTMLElement} element
 *
 * @returns {null | HTMLElement}
 */
const focusDescendent = (which, element) => {
	const {length} = element.children;
	const start = which === 'first' ? 0 : length - 1;
	const end = which === 'first' ? length : -1;
	const delta = which === 'first' ? 1 : -1;

	for (let index = start; index !== end; index += delta) {
		const child = /**@type {HTMLElement}*/(element.children[index]);

		if (which === 'first' && attemptFocus(child)) {
			return child;
		}

		const descendent = focusDescendent(which, child);

		if (descendent) {
			return descendent;
		}

		if (which === 'last' && attemptFocus(child)) {
			return child;
		}
	}

	return null;
};

/**
 * @param {object} props
 * @param {Function} [props.onClose]
 * @param {(value: string) => void} [props.setLocale]
 * @param {string} props.title
 * @param {boolean} props.isActive
 * @param {ComponentChildren} props.children
 */
export default ({onClose, setLocale, title, isActive, children}) => {
	const [id] = useState(() => `modal-${count += 1}`);
	// This has to be typed as `any` because the Preact typing for `useRef`
	// appears to convert `HTMLElement|null` (the inferred type for
	// `document.activeElement`) to `HTMLElement`.
	const originalFocus = useRef(/**@type {any}*/(document.activeElement));
	const localize = useLocalize();
	const modalRef = useRef();
	const previousFocus = useRef();
	const handleClose = useCallback(
		() => onClose && onClose(),
		[onClose]
	);
	const maybeLocaleMenu = setLocale ?
		html`<${LocaleMenu} onChange=${setLocale} />` : null;

	useEffect(() => {
		if (!isActive) {
			const current = /** @type HTMLElement */ (originalFocus.current);
			current.focus();
			return;
		}
		originalFocus.current = document.activeElement;
		previousFocus.current = focusDescendent('first', modalRef.current);
	}, [isActive]);

	useEffect(() => {
		if (!isActive) {
			return;
		}

		/**
		 * @param {FocusEvent} event
		 */
		const trapFocus = (event) => {
			const modal = modalRef.current;
			if (!modal.classList.contains('active')) {
				return;
			}

			const {relatedTarget} = event;
			let currentFocus;

			if (relatedTarget && !modal.contains(relatedTarget)) {
				const position = modal.compareDocumentPosition(relatedTarget);

				if (position & Node.DOCUMENT_POSITION_PRECEDING) {
					currentFocus = focusDescendent('last', modal);
				} else {
					currentFocus = focusDescendent('first', modal);
				}
			} else {
				currentFocus = relatedTarget;
			}

			previousFocus.current = currentFocus;
		};

		// Use the `blur` event rather than `focus` in order to capture cases
		// where focus would otherwise travel outside of the document.
		document.addEventListener('blur', trapFocus, true);

		return () => document.removeEventListener('blur', trapFocus, true);
	}, [isActive]);

	useEffect(() => {
		/**
		 * @param {KeyboardEvent} event
		 */
		const onKeydown = (event) => {
			if (event.keyCode !== keyCodes.escape) {
				return;
			}
			handleClose();
		};
		document.addEventListener('keydown', onKeydown);

		return () => document.removeEventListener('keydown', onKeydown);
	}, [handleClose]);

	return html`
		<div ref=${modalRef} class="modal ${isActive ? 'active' : ''}">
			<div class="mask" onClick=${handleClose}></div>
			<div role="dialog" aria-modal="true" aria-labelledby=${id}>
				<header class="clearfix">
					<button
						disabled=${!onClose}
						aria-label=${localize('CLOSE')}
						onClick=${handleClose}>
						${'\u00d7'}
					</button>
					<h1 id=${id}>${title}</h1>
				</head>
				<div class="content">
					${isActive ? children : null}
				</div>
				<footer>
					${maybeLocaleMenu}
				</footer>
			</div>
		</div>
	`;
};
