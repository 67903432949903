import { Fragment } from '../_snowpack/pkg/preact.js';
import { useCallback, useEffect, useState } from '../_snowpack/pkg/preact/hooks.js';
import { localize, LocaleContext } from '../locale.js';
import html from '../html.js';

import './room.css';

import ConnectionError from './connection-error.js';
import Errors from './errors.js';
import Intro from './intro.js';
import Menu from './menu.js';
import Modal from './modal.js';
import Progress from './progress.js';
import AskingPhase from './phase-asking.js';
import AnsweringPhase from './phase-answering.js';
import GuessingPhase from './phase-guessing.js';
import JoiningPhase from './phase-joining.js';
import ReviewingPhase from './phase-reviewing.js';
import useErrorLog from '../hooks/use-error-log.js';
import useConnectedness from '../hooks/use-connectedness.js';
import useKeepalive from '../hooks/use-keepalive.js';
import usePlayers from '../hooks/use-players.js';
import usePhase from '../hooks/use-phase.js';
import Footer from './footer.js';

/** @typedef { import("socket.io-client").Socket } Socket */
/** @typedef { import("../locale").LocaleName } LocaleName */
/** @typedef { import("../../types").CommandResult } CommandResult */
/** @typedef { import("../../types").ErrorData } ErrorData */

let commandCount = 0;
/**
 * @param {Socket} socket
 * @param {(errorData: ErrorData) => void} onError
 * @param {string} name
 * @param {any} value
 */
const sendCommand = (socket, onError, name, value) => {
	const id = ++commandCount;

	// Socket.io appears to queue messages emitted while the underlying
	// transport is disconnected. When the queued messages are delivered, they
	// may arrive before the relevant "join" message. The server rejects such
	// messages and responds with an error about the unrecognized connection.
	// That message doesn't indicate a problem, so displaying it to the player
	// would be misleading.
	//
	// Address this by silently refusing to send messages while the socket is
	// disconnected.
	if (!socket.connected) {
		return;
	}

	socket.emit(name, {id, value});

	const handleResult = /**@param {CommandResult} result */(result) => {
		if (result.id !== id) {
			return;
		}

		socket.off('result', handleResult);

		if (result.error) {
			onError(result.error);
		}
	};
	socket.on('result', handleResult);
};

/**
 * @param {object} props
 * @param {Socket} props.socket
 */
export default ({socket}) => {
	const [locale, setLocale] = useState(/**@type {LocaleName}*/('en-us'));
	// Manage the player's name in addition to the `Player` instance
	// representing the player so that if the instance is not available (i.e.
	// when the WebSocket disconnects), the player's name is persisted and can
	// be used to automatically re-join the game.
	const [name, setName] = useState('');
	const [me, players] = usePlayers(socket);
	const [errors, addError] = useErrorLog();
	const [isConnected, setIsConnected] = useState(socket.connected);
	const phase = usePhase(socket);
	const showWelcome = isConnected && me === null;
	const doneNames = phase && phase.data && 'done' in phase.data ?
		phase.data.done : null;

	useKeepalive(socket);

	useEffect(() => {
		if (isConnected && name && !me) {
			sendCommand(socket, addError, 'join', name);
		}
	}, [name, me, socket, isConnected, addError]);

	useConnectedness(socket, (connected) => {
		setIsConnected(connected);

		if (!connected) {
			addError({id: 'ERR_LOST_CXN'});
		}
	}, [addError]);

	const onJoin = useCallback(
		/**
		 * @param {string} newName
		 */
		(newName) => setName(newName),
		[]
	);
	const onAnswer = useCallback(
		/**
		 * @param {string} answer
		 */
		(answer) => sendCommand(socket, addError, 'answer', answer),
		[socket, addError]
	);
	const onGuess = useCallback(
		/**
		 * @param {object} values
		 * @param {string} values.actualAuthor
		 * @param {string|null} values.suspectedAuthor
		 */
		(values) => {
			sendCommand(socket, addError, 'guess', values);
		},
		[socket, addError]
	);
	const onMatch = useCallback(
		/**
		 * @param {string} playerName
		 */
		(playerName) => {
			sendCommand(socket, addError, 'match', playerName);
		},
		[socket, addError]
	);
	const onAdvance = useCallback(
		() => sendCommand(socket, addError, 'advance', null),
		[socket, addError]
	);

	const onQuestionEntry = useCallback((/**@type {string}*/text) => {
		sendCommand(socket, addError, 'ask', text);
	}, [socket, addError]);

	let content = null;
	if (phase === null || me === null) {
	} else if (phase.name === 'joining') {
		content = html`<${JoiningPhase} />`;
	} else if (phase.name === 'asking') {
		content = html`<${AskingPhase} me=${me} onQuestionEntry=${onQuestionEntry} />`;
	} else if (phase.name === 'answering') {
		content = html`<${AnsweringPhase} phase=${phase} me=${me} onAnswer=${onAnswer} />`;
	} else if (phase.name === 'guessing') {
		content = html`<${GuessingPhase} phase=${phase} me=${me} players=${players} onGuess=${onGuess} onMatch=${onMatch} />`;
	} else if (phase.name === 'reviewing') {
		content = html`<${ReviewingPhase} phase=${phase} me=${me} players=${players} />`;
	}

	return html`<${Fragment}>
		<${LocaleContext.Provider} value=${locale}>
			<${Modal} title="${localize(locale, 'CONNECTION_ERROR')}" isActive=${!isConnected} setLocale=${setLocale}>
				<${ConnectionError} />
			</${Modal}>

			<${Modal} title="Welcome to Friend Off!" isActive=${showWelcome} setLocale=${setLocale}>
				<${Intro} name=${name} players=${players} onJoin=${onJoin} />
			</${Modal}>

			<${Menu}
				player=${me}
				players=${players}
				done=${doneNames}
				onQuestionEntry=${onQuestionEntry}
				/>

			<${Errors} entries=${errors} />

			<section class="content">
				<${Progress} phase=${phase} player=${me} players=${players} onAdvance=${onAdvance} />
				${content}
			</section>
			<${Footer} setLocale=${setLocale} />
		</${LocaleContext.Provider}>
	</${Fragment}>`;
};
