/** @typedef {import("../../types").Answer} Answer */
/** @typedef {import("../../types").AllAnswers} AllAnswers */

/**
 * @param {Answer} answer
 * @param {AllAnswers} answers
 *
 * @returns {string[]}
 */
export default (answer, answers) => {
	return answer.equivalences
		.filter(({author, verified}) => verified && author in answers)
		.map(({author}) => answers[author].text);
};
