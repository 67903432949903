import { useEffect } from '../_snowpack/pkg/preact/hooks.js';

/** @typedef { import("socket.io-client").Socket } Socket */

/**
 * An "effect-based" hook for reacting to changes in socket connectedness. This
 * is not implemented as a boolean state so that the state change is easier to
 * use for logging.
 *
 * @param {Socket} socket
 * @param {(isConnected: boolean) => void} fn
 * @param {any[]} extraDependencies
 */
export default (socket, fn, extraDependencies) => {
	useEffect(() => {
		const handleChange = () => fn(socket.connected);

		socket.on('connect', handleChange);
		socket.on('disconnect', handleChange);

		return () => {
			socket.off('connect', handleChange);
			socket.off('disconnect', handleChange);
		};
	}, [socket, ...extraDependencies]);
};
