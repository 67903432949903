import { createContext } from './_snowpack/pkg/preact.js';
import { useContext } from './_snowpack/pkg/preact/hooks.js';

/** @typedef {keyof typeof locales} LocaleName */

export const LocaleContext = createContext(/**@type LocaleName*/('en-us'));

/**
 * @returns {LocaleName}
 */
export const useLocale = () => {
	return useContext(LocaleContext);
};

/**
 * @returns {(code: keyof enUs, ...others: any[]) => string}
 */
export const useLocalize = () => {
	const locale = useContext(LocaleContext);
	return (code, ...others) => localize(locale, code, ...others);
};

import enUs from './locales/en-us.js';
import enPirate from './locales/en-pirate.js';

const locales = {
	'en-us': enUs,
	'en-pirate': enPirate,
};

/**
 * @param {LocaleName} locale
 * @param {keyof enUs} code
 * @param {any[]} others
 */
export const localize = (locale, code, ...others) => {
	const text = locales[locale][code];
	return others.length ?
		text.replace(/{([1-9])}/g, (_, index) => others[index - 1]) :
		text;
};

/**
 * @template {string} T
 * @template {string} U
 * @template {string} V
 * @param {T} a
 * @param {U} b
 * @param {V} c
 * @returns {`${Uppercase<T>}${Uppercase<U>}${Uppercase<V>}`}
 */
export function toI18nKey(a, b, c) {
	return /**@type{`${Uppercase<T>}${Uppercase<U>}${Uppercase<V>}`}*/(
		`${a.toUpperCase()}${b.toUpperCase()}${c.toUpperCase()}`
	);
}
