import {Fragment} from '../_snowpack/pkg/preact.js';
import {useState} from '../_snowpack/pkg/preact/hooks.js';

import html from '../html.js';
import {useLocalize} from '../locale.js';

import {SpeechInput} from './speech.js';

/**
 * @typedef { import("../player").default } Player
 * @typedef { import("../../types").AnsweringPhase } AnsweringPhase
 */

/**
 * @param {object} props
 * @param {AnsweringPhase} props.phase
 * @param {Player} [props.me]
 * @param {(draft: string) => void} props.onAnswer
 */
export default ({phase, me, onAnswer}) => {
	const localize = useLocalize();
	const answer = me && phase.data.answers[me.name] || null;
	const [draft, setDraft] = useState(answer ? answer.text : '');
	const hasAnswered = answer && answer.text === draft;
	const onInput =
		/**
		 * @param {InputEvent} event
		 */
		(event) => {
			setDraft(/**@type {HTMLInputElement}*/(event.target).value.trim());
		};

	return html`
		<${Fragment}>
			<p>
				${localize('PLAYER_ASKS', phase.data.question.author, phase.data.question.text)}
			</p>

			<${SpeechInput}
				player=${me}
				message=${draft}
				onInput=${onInput}
				/>

			<button disabled=${hasAnswered} onClick=${() => onAnswer(draft)}>
				${localize(hasAnswered ? 'ANSWER_RECEIVED' : 'SAVE')}
			</button>
		</${Fragment}>
	`;
};
