import {useCallback, useEffect, useMemo, useRef, useState} from '../_snowpack/pkg/preact/hooks.js';

import {useLocalize} from '../locale.js';
import useCountdown from '../hooks/use-countdown.js';
import html from '../html.js';

import './progress.css';

/** @typedef { import("../../types").Phase } Phase */
/** @typedef { import("../../types").Player } Player */

let timeRemainingCount = 0;

/**
 * @param {object} props
 * @param {number} [props.endTime]
 */
const TimeRemaining = ({endTime}) => {
	const localize = useLocalize();
	const beepLowRef = useRef(/**@type {null|HTMLAudioElement}*/(null));
	const beepHighRef = useRef(/**@type {null|HTMLAudioElement}*/(null));
	const remaining = useCountdown(endTime);
	const minutes = Math.floor(remaining / 1000 / 60);
	const seconds = Math.round(remaining / 1000) % 60;
	const minutesString = endTime ? (minutes < 10 ? '0' : '') + minutes : '--';
	const secondsString = endTime ? (seconds < 10 ? '0' : '') + seconds : '--';
	const [muted, setMuted] = useState(false);
	const toggleMute = useCallback(() => setMuted(!muted), [muted]);
	const muteId = useMemo(() => `mute-${++timeRemainingCount}`, []);

	useEffect(() => {
		const audio = remaining > 3 * 1000 ?
			beepLowRef.current : beepHighRef.current;
		if (remaining > 10 * 1000 || !endTime || !audio) {
			return;
		}

		audio.currentTime = 0;
		audio.volume = muted ? 0 : 0.5;
		audio.play().catch(() => {});
	}, [remaining, endTime, muted]);

	return html`<div class="time-remaining" aria-live="polite">
		<div class="label">${localize('TIME_REMAINING')}</div>

		<input
			id=${muteId}
			checked=${muted}
			class="visually-hidden"
			onInput=${toggleMute}
			type="checkbox" />
		<label
			for=${muteId}
			aria-label=${localize(muted ? 'COUNTDOWN_AUDIO_ON' : 'COUNTDOWN_AUDIO_OFF')}>
		</label>

		<time datetime="${minutes}m ${seconds}s">
			${minutesString}:${secondsString}
		</time>
		<audio ref=${beepLowRef}>
			<source src="/audio/beep-low.mp3" type="audio/mp3" />
			<source src="/audio/beep-low.ogg" type="audio/ogg" />
		</audio>
		<audio ref=${beepHighRef}>
			<source src="/audio/beep-high.mp3" type="audio/mp3" />
			<source src="/audio/beep-high.ogg" type="audio/ogg" />
		</audio>
	</div>`;
};

/**
 * @param {object} props
 * @param {Phase | void} props.phase
 * @param {Player} [props.player]
 * @param {Player[]} props.players
 * @param {Function} props.onAdvance
 */
const Status = ({phase, player, players, onAdvance}) => {
	const endTime = phase && phase.data && 'endTime' in phase.data ?
		phase.data.endTime : null;
	let advance = null;

	if (phase && phase.data && 'done' in phase.data) {
		advance = html`
			<${Advance}
				phase=${phase.name}
				done=${phase.data.done}
				player=${player}
				players=${players}
				onAdvance=${onAdvance} />`;
	}

	return html`<div class="status">
		<${TimeRemaining} endTime=${endTime} />
		${advance}
	</div>`;
};

/**
 * @param {object} props
 * @param {Phase["name"]} props.phase
 * @param {string[]} props.done
 * @param {Player} [props.player]
 * @param {Player[]} props.players
 * @param {Function} props.onAdvance
 */
const Advance = ({phase, done, player, players, onAdvance}) => {
	const localize = useLocalize();
	const isDone = player && done.indexOf(player.name) > -1;
	const activePlayers = players.filter(({cxnId}) => !!cxnId);
	const relevantVotes = done.filter((name) => activePlayers.find((player) => player.name === name));
	const max = activePlayers.length;
	const value = relevantVotes.length;
	const percent = Math.round(100 * value / max);
	const style = `background: linear-gradient(to right, #09f ${percent}%, #eee ${percent}%);`;

	/** @type Parameters<typeof localize>[0] */
	let label;
	if (isDone) {
		label = 'ADVANCE_AFTER';
	} else if (phase === 'joining') {
		label = 'ADVANCE_FROM_JOINING';
	} else if (phase === 'guessing') {
		label = 'ADVANCE_FROM_GUESSING';
	} else if (phase === 'reviewing') {
		label = 'ADVANCE_FROM_REVIEWING';
	} else {
		label = 'ADVANCE_BEFORE';
	}

	return html`
		<div class="advance">
			<progress class="visually-hidden" max=${max} value=${value}>
			</progress>
			<button
				style="${style}"
				disabled=${isDone}
				onClick=${onAdvance}>
				${localize(label)}
			</button>
		</div>
	`;
};

/**
 * @param {object} props
 * @param {Phase | void} props.phase
 * @param {Player} [props.player]
 * @param {Player[]} props.players
 * @param {Function} props.onAdvance
 */
export default ({phase, player, players, onAdvance}) => {
	const localize = useLocalize();
	const name = phase ? phase.name : null;
	const round = phase ? phase.round + 1 : '?';

	return html`
		<div class="progress">
			<h2>${localize('PHASE_WITH_ROUND', round)}</h2>

			<ol class="phases">
				<li aria-current=${name === 'joining' ? 'page' : null}>
					${localize('PHASE_JOINING_NAME')}
				</li>
				<li aria-current=${name === 'asking' ? 'page' : null}>
					${localize('PHASE_ASKING_NAME')}
				</li>
				<li aria-current=${name === 'answering' ? 'page' : null}>
					${localize('PHASE_ANSWERING_NAME')}
				</li>
				<li aria-current=${name === 'guessing' ? 'page' : null}>
					${localize('PHASE_GUESSING_NAME')}
				</li>
				<li aria-current=${name === 'reviewing' ? 'page' : null}>
					${localize('PHASE_REVIEWING_NAME')}
				</li>
			</ol>

			<${Status}
				phase=${phase}
				player=${player}
				players=${players}
				onAdvance=${onAdvance} />
		</div>
	`;
};
