import { useCallback, useState } from '../_snowpack/pkg/preact/hooks.js';
import html from '../html.js';
import { useLocalize } from '../locale.js';
import {MAX_TEXT_LENGTH} from '../validation.js';

import './question-entry.css';

/** @typedef { import("../../types").Player } PlayerData */

/**
 * @param {object} props
 * @param {PlayerData} props.player
 * @param {(draft: string) => void} props.onChange
 */
export default ({player, onChange}) => {
	const localize = useLocalize();
	const [draft, setDraft] = useState(player.question ? player.question.text : '');

	/**
	 * @param {InputEvent} event
	 */
	const onInput = (event) => {
		setDraft(/**@type {HTMLInputElement}*/(event.target).value.trim());
	};

	const onSubmit = useCallback((/**@type {InputEvent}*/event) => {
		event.preventDefault();
		onChange(draft);
	}, [draft]);


	return html`
		<form class="question-entry" onSubmit=${onSubmit}>
			<p class="explanation">${localize('QUESTION_ENTRY_EXPLANATION')}</p>

			<textarea
				maxLength=${MAX_TEXT_LENGTH}
				onInput=${onInput}>${draft}</textarea>

			<div class="controls">
				<input
					type="submit"
					value=${localize('SAVE')}
					/>
			</div>
		</form>
	`;
};
