import {useCallback, useState} from '../_snowpack/pkg/preact/hooks.js';
import html from '../html.js';
import {useLocale} from '../locale.js';

import './locale-menu.css';

let count = 0;

/**
 * @param {object} props
 * @param {(value: string) => void} props.onChange
 */
export default ({onChange}) => {
	const locale = useLocale();
	const [domId] = useState(() => `locale-menu-${count += 1}`);
	const handleInput = useCallback(
		/**
		 * @param {InputEvent} event
		 */
		(event) => onChange(/**@type {HTMLButtonElement}*/(event.target).value),
		[onChange]
	);

	return html`
		<div class="i18n">
			<span>
				<input
					type="radio"
					class="visually-hidden"
					name="${domId}"
					onInput=${handleInput}
					id="${domId}-en-us"
					checked=${locale === 'en-us' ? 'checked' : ''}
					value="en-us" />
				<label for=${domId}-en-us >English</label>

				<input
					type="radio"
					class="visually-hidden"
					name="${domId}"
					onInput=${handleInput}
					id="${domId}-en-pirate"
					checked=${locale === 'en-pirate' ? 'checked' : ''}
					value="en-pirate" />
				<label for=${domId}-en-pirate>Pirate</label>
			</span>
		</div>
	`;
};
