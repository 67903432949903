// NOTE: This file is also used by the server code, so it's presence in the
// directory named "client" is admittedly somewhat misleading. I placed it here
// because I haven't found a good way for the client build process to include
// JavaScript files outside of the client directory.
/**
 * @typedef { import("../types").Guess } Guess
 * @typedef { import("../types").Validator<string> } StringValidator
 * @typedef { import("../types").Validator<number> } NumberValidator
 * @typedef { import("../types").Validator<Guess> } GuessValidator
 * @typedef { import("../types").Validator<null> } NullValidator
 */

export const MAX_ROOM_NAME_LENGTH = 20;

/** @type {StringValidator} */
export const validateRoomName = (input) => {
	if (typeof input !== 'string') {
		return {
			isValid: false,
			reason: 'Room name not specified',
		};
	}

	const sanitized = input.toLowerCase().replace(/\s/g, '-');

	if (sanitized.length > MAX_ROOM_NAME_LENGTH) {
		return {
			isValid: false,
			reason: `Room names may not exceed ${MAX_ROOM_NAME_LENGTH} characters in length`,
		};
	}

	if (!/^[a-zA-Z0-9-]+$/.test(sanitized)) {
		return {
			isValid: false,
			reason: 'Room names may only contain letters, numbers, and dashes',
		};
	}

	return {
		isValid: true,
		value: sanitized,
	};
};


export const MAX_PLAYER_NAME_LENGTH = 20;

/** @type {StringValidator} */
export const validatePlayerName = (input) => {
	if (typeof input !== 'string') {
		return {
			isValid: false,
			reason: 'Player name not specified.',
		};
	}

	const trimmed = input.trim();

	if (trimmed.length > MAX_PLAYER_NAME_LENGTH) {
		return {
			isValid: false,
			reason: `Player name may not exceed ${MAX_PLAYER_NAME_LENGTH} characters in length`,
		};
	}

	return {
		isValid: true,
		value: trimmed,
	};
};

export const MAX_TEXT_LENGTH = 255;

/** @type {StringValidator} */
export const validateText = (input) => {
	if (typeof input !== 'string') {
		return {
			isValid: false,
			reason: 'Text not specified.',
		};
	}

	const trimmed = input.trim();

	if (trimmed.length > MAX_TEXT_LENGTH) {
		return {
			isValid: false,
			reason: `Text may not exceed ${MAX_TEXT_LENGTH} characters in length`,
		};
	}

	return {
		isValid: true,
		value: trimmed,
	};
};

/** @type {GuessValidator} */
export const validateGuess = (input) => {
	if (!input) {
		return {
			isValid: false,
			reason: 'Guess not specified',
		};
	}

	return {
		isValid: true,
		value: input,
	};
};

/** @type {NullValidator} */
export const validateNull = (input) => {
	if (input !== null) {
		return {
			isValid: false,
			reason: 'Unexpected value provided.',
		};
	}

	return {
		isValid: true,
		value: null,
	};
};
