import {Fragment} from '../_snowpack/pkg/preact.js';
import { useCallback, useState } from '../_snowpack/pkg/preact/hooks.js';

import html from '../html.js';
import {validateRoomName} from '../validation.js';
import {useLocalize, LocaleContext} from '../locale.js';
import LocaleMenu from './locale-menu.js';
import Demo from './demo.js';
import {DESTROY_EMPTY_ROOM} from '../dummy.js';
import ExternalLink from './external-link.js';

import './landing.css';

/** @typedef { import("../locale").LocaleName } LocaleName */

/**
 * @param {object} props
 * @param {(value: string) => void} props.setLocale
 */
const Content = ({setLocale}) => {
	const localize = useLocalize();
	const [roomName, setRoomName] = useState('');
	const result = validateRoomName(roomName);
	const validationMessage = (roomName && !result.isValid) ?
		html`<p>${result.reason}</p>` :
		null;

	const onSubmit = useCallback((/**@type {InputEvent}*/event) => {
		event.preventDefault();

		if (!result.isValid) {
			return;
		}

		location.pathname = roomName;
	}, [roomName, result.isValid]);

	/**
	 * @param {InputEvent} event
	 */
	const onInput = (event) => {
		setRoomName(/**@type {HTMLInputElement}*/(event.target).value);
	};

	return html`
		<div class="landing">
			<h1>Friend Off!</h1>

			<${LocaleMenu} onChange=${setLocale} />

			<form onSubmit=${onSubmit}>
				<label>
					${localize('PICK_A_ROOM_NAME')}
					<input
						class="room-name"
						onInput=${onInput}
						value=${result.isValid ? result.value : roomName}
						/>
				</label>

				<input
					type="submit"
					value="${localize('ENTER_ROOM')}"
					disabled=${!result.isValid}
					/>
				${validationMessage}
			</form>

			<p>${localize('WHAT_IS_FRIENDOFF_ANSWER_P1')}</p>

			<${Demo} />

			<p>
				${localize('WHAT_IS_FRIENDOFF_ANSWER_P2')}
				<${ExternalLink} href="${DESTROY_EMPTY_ROOM}">
					${localize('WHAT_IS_FRIENDOFF_ANSWER_SOURCE_LINK_TEXT')}
				</${ExternalLink}>
			</p>
		</div>
	`;
};

export default () => {
	const [locale, setLocale] = useState(/**@type {LocaleName}*/('en-us'));

	return html`
		<${Fragment}>
			<${LocaleContext.Provider} value=${locale}>
				<${Content} setLocale=${setLocale} />
			</${LocaleContext.Provider}>
		</${Fragment}>
	`;
};
