import { useEffect, useState } from '../_snowpack/pkg/preact/hooks.js';
import Player from '../player.js';

/** @typedef { import("../../types").Player } PlayerData */
/** @typedef { import("socket.io-client").Socket } Socket */

/**
 * @param {Socket} socket
 */
export default (socket) => {
	const [players, setPlayers] = useState(/**@type {Player[]}*/([]));
	const me = players.find((player) => player.cxnId === socket.id) || null;

	useEffect(() => {
		/**
		 * @param {PlayerData[]} newPlayers
		 */
		function handlePlayer(newPlayers) {
			setPlayers(newPlayers.map((playerData) => new Player(playerData)));
		}
		/**
		 * @param {object} state
		 * @param {PlayerData[]} state.players
		 */
		function handleState ({players: newPlayers}) {
			setPlayers(newPlayers.map((playerData) => new Player(playerData)));
		}
		socket.on('players', handlePlayer);
		socket.on('state', handleState);

		return () => {
			socket.off('players', handlePlayer);
			socket.off('state', handleState);
		};
	}, []);

	return [me, players];
};
