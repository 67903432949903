import { render } from './_snowpack/pkg/preact.js';
import html from './html.js';
import {io} from './_snowpack/pkg/socket.io-client.js';

import './index.css';
import Landing from './components/landing.js';
import Room from './components/room.js';
import {validateRoomName} from './validation.js';

const result = validateRoomName(location.pathname.slice(1));
const main = document.getElementsByTagName('main')[0];

const websocketDomain = (document.cookie
	.split('; ')
	.find((row) => row.startsWith('websocket-domain=')) || '')
	.split('=')[1];

if (result.isValid) {
	const socket = io(websocketDomain, {auth: {roomName: result.value}});
	render(html`<${Room} socket=${socket} />`, main);
} else {
	render(html`<${Landing} />`, main);
}
