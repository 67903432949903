import {Fragment} from '../_snowpack/pkg/preact.js';
import {useCallback, useState} from '../_snowpack/pkg/preact/hooks.js';
import html from '../html.js';
import {useLocalize} from '../locale.js';

import Affinity from './affinity.js';
import QuestionEntry from './question-entry.js';
import Modal from './modal.js';
import ExternalLink from './external-link.js';
import {
  CHOOSE_QUESTION, HOW_TO_TRANSLATE, WHAT_MAKES_IT_FOSS_UI
} from '../dummy.js';

import './menu.css';

/**
 * @typedef { import("../player").default } Player
 */

const Help = () => {
	const localize = useLocalize();
	// BEGIN NOTABLE SOURCE CODE REGION "WHAT_MAKES_IT_FOSS_UI"
	return html`
		<ul>
			<li><a href="#help-what-is-friend-off">${localize('WHAT_IS_FRIENDOFF')}</a></li>
			<li><a href="#help-what-happens-in-each-phase">${localize('WHAT_HAPPENS_IN_EACH_PHASE')}</a></li>
			<li><a href="#help-what-makes-it-foss">${localize('WHAT_MAKES_IT_FOSS')}</a></li>
			<li><a href="#help-translating-friend-off">${localize('TRANSLATING_FRIEND_OFF')}</a></li>
			<li><a href="#help-credits">${localize('CREDITS')}</a></li>
		</ul>

		<h3>
			<a name="help-what-is-friend-off">
				${localize('WHAT_IS_FRIENDOFF')}
			</a>
		</h3>

		<p>${localize('WHAT_IS_FRIENDOFF_ANSWER_P1')}</p>
		<p>${localize('WHAT_IS_FRIENDOFF_ANSWER_P2')}</p>

		<a href="https://gitlab.com/jugglinmike/friend-off">
			https://gitlab.com/jugglinmike/friend-off
		</a>

		<h3>
			<a name="help-what-happens-in-each-phase">
				${localize('WHAT_HAPPENS_IN_EACH_PHASE')}
			</a>
		</h3>

		<dl>
			<dt>${localize('PHASE_JOINING_NAME')}</dt>
			<dd>${localize('PHASE_JOINING_DESC')}</dd>
			<dt>${localize('PHASE_ASKING_NAME')}</dt>
			<dd>
				${localize('PHASE_ASKING_DESC')}
				<${ExternalLink} href="${CHOOSE_QUESTION}">
					${localize('PHASE_ASKING_SOURCE_LINK_TEXT')}
				</${ExternalLink}>
			</dd>
			<dt>${localize('PHASE_ANSWERING_NAME')}</dt>
			<dd>${localize('PHASE_ANSWERING_DESC')}</dd>
			<dt>${localize('PHASE_GUESSING_NAME')}</dt>
			<dd>${localize('PHASE_GUESSING_DESC')}</dd>
			<dt>${localize('PHASE_REVIEWING_NAME')}</dt>
			<dd>${localize('PHASE_REVIEWING_DESC')}</dd>
		</dl>

		<h3>
			<a name="help-what-makes-it-foss">
				${localize('WHAT_MAKES_IT_FOSS')}
			</a>
		</h3>

		<blockquote>${localize('WHAT_MAKES_IT_FOSS_ANSWER_1')}</blockquote>

		<p>- <a href="https://gnu.org">gnu.org</a></p>

		<p>
			${localize('WHAT_MAKES_IT_FOSS_ANSWER_2')}
			<${ExternalLink} href="${WHAT_MAKES_IT_FOSS_UI}">
				${localize('WHAT_MAKES_IT_FOSS_ANSWER_SOURCE_LINK_TEXT')}
			</${ExternalLink}>
		</p>

		<h3>
			<a name="help-translating-friend-off">
				${localize('TRANSLATING_FRIEND_OFF')}
			</a>
		</h3>

		<p>
			${localize('TRANSLATING_FRIEND_OFF_EXPLAINED')}
			<${ExternalLink} href="${HOW_TO_TRANSLATE}">
				${localize('TRANSLATING_FRIEND_OFF_SOURCE_LINK_TEXT')}
			</${ExternalLink}>
		</p>

		<h3>
			<a name="help-credits">
				${localize('CREDITS')}
			</a>
		</h3>

		<p dangerouslySetInnerHTML="${{__html: localize('CREDITS_FONT_AWESOME')}}"></p>

		<p dangerouslySetInnerHTML="${{__html: localize('CREDITS_I18N_LOGO')}}"></p>
	`;
	// END NOTABLE SOURCE CODE REGION "WHAT_MAKES_IT_FOSS_UI"
};

/**
 * @param {object} props
 * @param {Player} props.player
 * @param {Player['name'][]} props.done
 */
const PlayerItem = ({player, done}) => {
	const classList = `player ${player.cxnId ? 'online' : 'offline'} ` +
		(done.includes(player.name) ? 'voted-yes' : 'voted-no');
	return html`
		<dt class=${classList}>${player.name}</dt>
		<dd class=${classList}>${player.score}</dd>
	`;
};

/**
 * @param {object} props
 * @param {null | Player['name'][]} props.done
 * @param {Player[]} props.players
 */
const Standings = ({players, done}) => {
	const localize = useLocalize();

	const playerItems = players.slice()
		.sort((player1, player2) => player2.score - player1.score)
		.map((player) => html`<${PlayerItem} player=${player} done=${done || []} />`);

	return html`<${Fragment}>
		<h2>${localize('STANDINGS')}</h2>

		<dl class="players ${done ? 'voting-yes' : 'voting-no'}">
			${playerItems}
		</dl>
	</${Fragment}>`;
};

/**
 * @param {object} props
 * @param {Player} [props.player]
 * @param {Player[]} props.players
 * @param {null | Player['name'][]} props.done
 * @param {(value: string) => void} props.onQuestionEntry
 */
export default ({player, players, done, onQuestionEntry}) => {
	const localize = useLocalize();
	const [isEnteringQuestion, setIsEnteringQuestion] = useState(false);
	const [isViewingAffinities, setIsViewingAffinities] = useState(false);
	const [isViewingHelp, setIsViewingHelp] = useState(false);
	const onCloseQuestionEntry = useCallback(
		() => setIsEnteringQuestion(false), [setIsEnteringQuestion]
	);
	const onChangeQuestion = useCallback(
		/**
		 * @param {string} value
		 */
		(value) => {
			onQuestionEntry(value);
			onCloseQuestionEntry();
		},
		[onCloseQuestionEntry]
	);

	const enterQuestionClass = (player && player.question) ? '' : 'question-empty';

	return html`<section class="menu">
		<header>
			<h1>Friend Off!</h1>
		</header>

		<${Modal}
			title=${localize('YOUR_QUESTION')}
			isActive=${isEnteringQuestion}
			onClose=${onCloseQuestionEntry}
			>
			<${QuestionEntry}
				player=${player}
				onChange=${onChangeQuestion}
				/>
		</${Modal}>

		<${Modal}
			title=${localize('AFFINITIES')}
			isActive=${isViewingAffinities}
			onClose=${() => setIsViewingAffinities(false)}
			>
			<${Affinity} players=${players} />
		</${Modal}>

		<${Modal}
			title=${localize('HELP')}
			isActive=${isViewingHelp}
			onClose=${() => setIsViewingHelp(false)}
			>
			<${Help} />
		</${Modal}>

		<nav>
			<ul>
				<li class="question ${enterQuestionClass}">
					<button onClick=${() => setIsEnteringQuestion(true)}>
						${localize('YOUR_QUESTION')}
					</button>
				</li>
				<li class="affinities">
					<button onClick=${() => setIsViewingAffinities(true)}>
						${localize('AFFINITIES')}
					</button>
				</li>
				<li class="help">
					<button onClick=${() => setIsViewingHelp(true)}>
						${localize('HELP')}
					</button>
				</li>
			</ul>
		</nav>

		<${Standings} players=${players} done=${done} />
	</section>`;
};
