import {Fragment} from '../_snowpack/pkg/preact.js';
import html from '../html.js';
import {useLocalize} from '../locale.js';
import getAlternates from '../util/get-alternates.js';

import {Speech} from './speech.js';

/**
 * @typedef { import("../player").default } Player
 * @typedef { import("../../types").ReviewingPhase } ReviewingPhase
 */

/**
 * @param {object} props
 * @param {Player} props.me
 * @param {ReviewingPhase} props.phase
 * @param {Player[]} props.players
 */
export default ({me, phase, players}) => {
	const localize = useLocalize();
	const answers = phase.data.answers;
	const results = {
		allGuesses: phase.data.guesses,
		allAnswers: phase.data.answers,
		me,
		players
	};
	const mine = answers[me.name] ?
		html`<${Speech}
			player=${me}
			message=${answers[me.name].text}
			results=${results}
			alternates=${getAlternates(answers[me.name], answers)}
			/>` :
		null;
	const others = Object.values(answers)
		.filter((answer) => answer.author !== me.name)
		.map((answer) => {
			const player = players.find(({name}) => name === answer.author);
			return html`<${Speech}
				player=${player}
				message=${answer.text}
				results=${results}
				alternates=${getAlternates(answer, answers)}
				/>`;
		});

	return html`
		<${Fragment}>
			<p>
				${localize('PLAYER_ASKS', phase.data.question.author, phase.data.question.text)}
			</p>

			${mine}

			${others}
		</${Fragment}>
	`;
};
