/**
 * @typedef { import("../types").Player } PlayerData
 */

const colors = [
	'#4c90ba',
	'#2bc2c2',
	'#f4b811',
	'#de663e',
	'#ff912b',
];

export default class Player {
	/**
	 * @param {PlayerData} data
	 */
	constructor(data) {
		this.name = data.name;
		this.question = data.question;
		this.cxnId = data.cxnId;
		this.standings = data.standings;
	}

	get initial() {
		return (this.name[0] || '').toUpperCase();
	}

	get color() {
		const name = this.name;
		if (!name) {
			return 'transparent';
		}

		let hash = 0;

		for (let index = 0; index < name.length; index += 1) {
			hash += name.charCodeAt(index);
		}

		return colors[hash % colors.length];
	}

	/**
	 * Calculate a global score for the user: the number of answers they
	 * correctly guessed less the number of answers they misattributed. Unlike
	 * affinity calculations, players are not further penalized by the fact
	 * that a misattribution actually demonstrates unfamiliarity with two
	 * players. Put differently: the "missed" guess outcome is intentionally
	 * unused here.
	 *
	 * @returns {number}
	 */
	get score() {
		return Object.values(this.standings)
			.reduce((total, outcomes) => outcomes[0] - outcomes[1] + total, 0);
	}
}

export class PlayerUnknown extends Player {
	constructor() {
		super({
			name: '???',
			avatar: null,
			cxnId: null,
			question: null,
			standings: {},
		});
	}

	get color() {
		return '#555';
	}
}
