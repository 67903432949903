import {useEffect} from '../_snowpack/pkg/preact/hooks.js';

/** @typedef { import("socket.io-client").Socket } Socket */

/**
 * This application is deployed to the Glitch platform. Glitch is designed to
 * persist application servers only as long as they are actively serving
 * requests. It appears that it does not consider active WebSocket connections
 * when determining whether a given server is active. If all players are
 * actively playing but using that transport, then the server will shut down.
 *
 * The following function triggers an HTTP request and discards the response.
 * If invoked in response to user input, it will serve as a signal to the
 * Glitch service that the server is still in use and should not be shut down.
 *
 * @param {Socket} socket
 */
export default (socket) => {
	useEffect(() => {
		socket.on('keepalive', () => {
			new Image().src = '/.status?keepalive&' + Date.now();
		});
	}, [socket]);
};
