import html from '../html.js';
/** @typedef { import("../player").default } Player */

/**
 * @param {Player} playerA
 * @param {Player} playerB
 */
const getScore = (playerA, playerB) => {
	if (playerA.name === playerB.name) {
		return null;
	}
	const aToB = playerA.standings[playerB.name] || [0, 0, 0];
	const bToA = playerB.standings[playerA.name] || [0, 0, 0];
	return aToB[0] - aToB[1] - aToB[2] + bToA[0] - bToA[1] - bToA[2];
};

/**
 * @param {object} props
 * @param {Player[]} props.players
 */
export default ({players}) => {
	const columns = html`<tr>
		<td></td>
		${players.map((player) => html`<th scope="col">${player.name}</th>`)}
	</tr>`;
	const rows = players.map((playerA) => {
		const columns = players.map((playerB) => html`<td>${getScore(playerA, playerB)}</td>`);

		return html`<tr>
			<th scope="row">${playerA.name}</th>
			${columns}
		</tr>`;
	});
	return html`<table>
		<thead>${columns}</thead>
		<tbody>${rows}</tbody>
	</table>`;
};
