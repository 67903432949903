/**
 * Design note: the body of this phase is fairly useless, and in some respects,
 * it would be more helpful to render the "Question Entry" component in-line.
 * A previous iteration of the application did that, but it had a few negative
 * consequences:
 *
 * - players who were in the process of entering a question were interrupted by
 *   the phase transition triggered by the submission of another player
 * - players were slower to recognize that they could enter a question at any
 *   moment during gameplay--not just at the beginning of each round
 */
import html from '../html.js';
import {useLocalize} from '../locale.js';

/**
 * @typedef { import("../player").default } Player
 */

export default () => {
	const localize = useLocalize();

	return html`
		<p>${localize('PHASE_ASKING_PROMPT')}</p>
	`;
};
