import * as d3 from '../_snowpack/pkg/d3.js';
import '../_snowpack/pkg/d3-force.js';
import { useCallback } from '../_snowpack/pkg/preact/hooks.js';
import html from '../html.js';

/**
 * @typedef { import("../player").default } Player
 * @typedef { import("../../types").PlayerName } PlayerName
 */

/**
 * @typedef {object} Link
 * @property {PlayerName} source
 * @property {PlayerName} target
 * @property {number} value
 */

/**
 * @param {Player[]} players
 * @returns {Link[]}
 */
const makeLinks = (players) => {
	const links = [];
	for (const player of players) {
		for (const [otherName, outcomes] of Object.entries(player.standings)) {
			links.push({
				source: player.name,
				target: otherName,
				value: outcomes[0] - outcomes[1] - outcomes[2],
			});
		}
	}

	return links;
};

/**
 * @param {SVGElement} node
 * @param {Player[]} players
 */
function render(node, players) {
	const links = makeLinks(players);
	const nodes = players.map(({name, color}) => ({index: 0, id: name, color}));
	const width = 800;
	const height = 600;
	const radius = 25;
	const maxDistance = 300;

	const max = Math.max(...links.map(({value}) => value));
	const min = Math.min(...links.map(({value}) => value));

	const simulation = d3.forceSimulation(nodes)
		.force('link', d3.forceLink(links)
			.id(/**@param {any} d*/d => d.id)
			.distance((d) => radius + maxDistance * (d.value - max)/(min - max))
		)
		.force('collide', d3.forceCollide(radius))
		.force('charge', d3.forceManyBody())
		.force('center', d3.forceCenter(width / 2, height / 2));

	const svg = d3.select(node);
	svg.attr('viewBox', /**@type {any}*/([0, 0, width, height]));

	const linkSelection = svg
		.selectAll('.links')
		.data([true])
		.join('g')
			.attr('class', 'links')
			.attr('stroke', '#999')
			.attr('stroke-opacity', 0.6)
			.selectAll('line')
			.data(/**@type {any[]}*/(links))
				.join('line')
				.attr('stroke-width', d => Math.sqrt(d.value));

	const playerSelection = svg
		.selectAll('.players')
		.data([true])
		.join('g')
			.attr('class', 'players ' + Math.random())
			.selectAll('.player')
			.data(/**@type {any[]}*/(nodes), (node) => node.id)
				.join('g')
				.attr('class', 'player');

	playerSelection
		.append('circle')
		.attr('stroke', '#fff')
		.attr('stroke-width', 1.5)
		.attr('r', radius)
		.attr('fill', (d) => d.color);
	playerSelection
		.append('text').text((d) => d.id)
		.attr('fill', '#fff')
		.attr('dominant-baseline', 'middle')
		.attr('text-anchor', 'middle');

	playerSelection
		.append('title')
		.text(d => d.id);

	simulation.on('tick', () => {
		linkSelection
			.attr('x1', d => d.source.x)
			.attr('y1', d => d.source.y)
			.attr('x2', d => d.target.x)
			.attr('y2', d => d.target.y);

		playerSelection
			.attr('transform', d => `translate(${d.x},${d.y})`);
	});
}

/**
 * @param {object} props
 * @param {Player[]} props.players
 */
export default ({players}) => {
	const ref = useCallback(/** @param {SVGElement} node */(node) => {
		render(node, players);
	}, [players]);
	return html`<svg ref=${ref}></svg>`;
};
