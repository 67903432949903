import {Fragment} from '../_snowpack/pkg/preact.js';
import {useState} from '../_snowpack/pkg/preact/hooks.js';
import html from '../html.js';
import {useLocalize} from '../locale.js';

import './footer.css';

import LocaleMenu from './locale-menu.js';
import Modal from './modal.js';
import projects from '../dummy.js';

const Licenses = () => {
	const localize = useLocalize();
	const info = projects.map((project) => html`
		<div class="external-project">
			<h3>${project.name} (${localize('VERSION')} ${project.version})</h3>

			<a href=${project.repository}>
				${project.repository}
			</a>

			<details>
				<summary>${project.license.name} license</summary>

				<pre class="license">${project.license.text}</pre>
			</details>
		</div>
	`);

	return html`
		<${Fragment}>
			<p>${localize('FRIEND_OFF_IS_FREE_SOFTWARE_LONG')}</p>

			<p>${localize('FREE_SOFTWARE_DEPENDENCIES', info.length)}</p>

			${info}
		</${Fragment}>
	`;
};

/**
 * @param {object} props
 * @param {(value: string) => void} props.setLocale
 */
export default ({setLocale}) => {
	const localize = useLocalize();
	const [isViewingLicenses, setIsViewingLicenses] = useState(false);

	return html`
		<${Fragment}>
			<footer>
				<${LocaleMenu} onChange=${setLocale} />

				<div class="free-software">
					${localize('FRIEND_OFF_IS_FREE_SOFTWARE_SHORT')}
					<button
						onClick=${() => setIsViewingLicenses(true)}>
						${localize('VIEW_LICENSES')}
					</button>
				</div>
			</footer>

			<${Modal}
				title=${localize('LICENSES')}
				isActive=${isViewingLicenses}
				onClose=${() => setIsViewingLicenses(false)}
				>
				<${Licenses} />
			</${Modal}>
		</${Fragment}>
	`;
};
