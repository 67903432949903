import html from '../html.js';
import {useLocalize} from '../locale.js';

import './external-link.css';

/** @typedef { import("preact").ComponentChildren } ComponentChildren */

/**
 * @param {object} props
 * @param {string} props.href
 * @param {ComponentChildren} props.children
 */
export default ({href, children}) => {
	const localize = useLocalize();

	return html`
		<a
			href="${href}"
			target="_blank"
			aria-description=${localize('LINK_OPENS_IN_NEW_WINDOW')}
			>
			${children}
		</a>
	`;
};
