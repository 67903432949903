import {Fragment} from '../_snowpack/pkg/preact.js';

import html from '../html.js';
import {useLocalize} from '../locale.js';
import getAlternates from '../util/get-alternates.js';

import {Speech, SpeechGuess} from './speech.js';

/**
 * @typedef { import("../player").default } Player
 * @typedef { import("../../types").GuessingPhase } GuessingPhase
 * @typedef {({actualAuthor, suspectedAuthor} : {actualAuthor: string, suspectedAuthor: string|null}) => void} OnGuess
 * @typedef {(name: string) => void} OnMatch
 */

/**
 * @param {object} props
 * @param {Player} props.me
 * @param {GuessingPhase} props.phase
 * @param {Player[]} props.players
 * @param {OnGuess} props.onGuess
 * @param {OnMatch} props.onMatch
 */
export default ({me, phase, players, onGuess, onMatch}) => {
	const localize = useLocalize();
	const answers = phase.data.answers;
	const myAnswer = answers[me.name];
	const mine = answers[me.name] ?
		html`<${Speech}
			player=${me}
			alternates=${getAlternates(myAnswer, answers)}
			message=${answers[me.name].text} />` :
		null;
	const otherPlayers = players.filter(({name}) => name !== me.name);
	const others = Object.values(answers)
		.filter((answer) => answer.author !== me.name)
		.map((answer) => {
			/**@param {string|null} suspectedAuthor */
			const onChange = (suspectedAuthor) => {
				onGuess({actualAuthor: answer.author, suspectedAuthor});
			};
			return html`<${SpeechGuess}
				players=${otherPlayers}
				answer=${answer}
				myAnswer=${myAnswer}
				alternates=${getAlternates(answer, answers)}
				onChange=${onChange}
				onMatch=${onMatch}
				/>`;
		});

	return html`
		<${Fragment}>
			<p>
				${localize('PLAYER_ASKS', phase.data.question.author, phase.data.question.text)}
			</p>
			${mine}
			${others}
		</${Fragment}>
	`;
};
