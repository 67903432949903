import {useLocalize} from '../locale.js';
import html from '../html.js';

export default () => {
	const localize = useLocalize();

	return html`
		<p>${localize('PHASE_JOINING_DESC')}</p>

		<p>${localize('PHASE_JOINING_SUGGESTION')}</p>
	`;
};
