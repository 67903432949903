import {useCallback, useState} from '../_snowpack/pkg/preact/hooks.js';

/** @typedef {import("../../types").ErrorData} ErrorData */
/** @typedef {import("../../types").ErrorLogEntry} ErrorLogEntry */

/**
 * Unlike typical hooks, this hook's "setter" must be provided to dependency
 * arrays (because it encapsulates state).
 *
 * @returns {[ErrorLogEntry[], (data: ErrorData) => void]}
 */
export default () => {
	const [errors, setErrors] = useState(/**@type ErrorLogEntry[]*/([]));

	const addError = useCallback(
		/**
		 * @param {ErrorData} data
		 */
		(data) => {
			setErrors(errors.concat({
				timestamp: new Date(),
				data,
			}));
		},
		[errors]
	);

	return [errors, addError];
};
