import {Fragment} from '../_snowpack/pkg/preact.js';
import {useCallback, useState} from '../_snowpack/pkg/preact/hooks.js';

import html from '../html.js';
import {useLocalize} from '../locale.js';
import Modal from './modal.js';

import './errors.css';

const nbsp = String.fromCharCode(160);

/** @typedef {import("../../types").ErrorData} ErrorData */
/** @typedef {import("../../types").ErrorLogEntry} ErrorLogEntry */

/**
 * @param {object} props
 * @param {ErrorLogEntry} props.entry
 * @param {() => void} props.dismiss
 */
const ErrorLogEntry = ({entry, dismiss}) => {
	const localize = useLocalize();
	return html`
		<${Fragment}>
			<span class="message">
				${localize('ERROR_LABEL')}:${nbsp}
				${localize(entry.data.id, entry.data.context)}
			</span>

			<button onclick=${dismiss}>Dismiss</button>
		</${Fragment}>
	`;
};

/**
 * @param {object} props
 * @param {ErrorLogEntry[]} props.entries
 */
const ErrorLog = ({entries}) => {
	const localize = useLocalize();

	const rows = entries.slice().reverse().map((entry) => {
		return html`<tr>
			<td class="timestamp">${entry.timestamp.toLocaleTimeString()}</td>
			<td>${localize(entry.data.id, entry.data.context)}</td>
		</tr>`;
	});

	return html`
		<table>
			<tr>
				<th>${localize('ERROR_TIME')}</th>
				<th>${localize('ERROR_MESSAGE')}</th>
			</tr>
			${rows}
		</table>
	`;
};

/**
 * @param {object} props
 * @param {ErrorLogEntry[]} props.entries
 */
export default ({entries}) => {
	const [viewedIndex, setViewedIndex] = useState(-1);
	const [isViewingLog, setIsViewingLog] = useState(false);
	const dismiss = useCallback(() => {
		setViewedIndex(entries.length - 1);
	}, [entries]);
	const localize = useLocalize();

	const maybeEntry = entries.length - 1 > viewedIndex ?
		html`
			<${ErrorLogEntry}
				dismiss=${dismiss}
				entry=${entries[entries.length - 1]} />
		` :
		null;

	return html`
		<div class="errors">
			<div class="hud">
				${maybeEntry}
				<button onclick=${() => setIsViewingLog(true)}>
					${localize('ERRORS')} (${entries.length})
				</button>
			</div>

			<${Modal}
				title=${localize('ERROR_LOG')}
				isActive=${isViewingLog}
				onClose=${() => setIsViewingLog(false)}
				>
				<${ErrorLog} entries=${entries} />
			</${Modal}>
		</div>
	`;
};
