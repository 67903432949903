import { useEffect, useState } from '../_snowpack/pkg/preact/hooks.js';

/** @typedef { import("../../types").Phase } Phase */
/** @typedef { import("socket.io-client").Socket } Socket */

/**
 * @param {Socket} socket
 */
export default (socket) => {
	const [phase, setPhase] = useState(/** @type {Phase|null} */(null));

	useEffect(() => {
		/**
		 * @param {Phase} phase
		 */
		function handlePhase(phase) {
			setPhase(phase);
		}
		/**
		 * @param {object} state
		 * @param {Phase} state.phase
		 */
		function handleState({phase}) {
			setPhase(phase);
		}
		socket.on('phase', handlePhase);
		socket.on('state', handleState);

		return () => {
			socket.off('phase', handlePhase);
			socket.off('state', handleState);
		};
	}, []);

	return phase;
};

